import React, { useContext, useEffect } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { useLocation } from "@reach/router"

 
import { TwitterNews, FacebookNews, InstagramNews, LinkedinNews, ArrowDown } from '@components/icon/icon'

import './styles/_index.scss';

const SocialShare = (props) => {
	const location = useLocation();
	return (
		<div className="social-share">
			<Container> 
				<h3>Share this Post</h3>
				<div className="socials">
					<ul>
						<li>
							<a href={`https://www.facebook.com/sharer?u=${location.href}`} target="_blank">

								<span>Facebook</span> <FacebookNews /></a></li>
						<li>
							<a href={`https://twitter.com/intent/tweet?url=${location.href}`} target="_blank">

								<span>Twitter</span> <TwitterNews /></a></li>
						<li><a href="#" target="_blank"><span>Instagram</span> <InstagramNews /></a></li>
						<li><a href={`https://www.linkedin.com/cws/share?url=${location.href}`} target="_blank"><span>Linkedin</span> <LinkedinNews /></a></li>
					</ul>
				</div>

			</Container>
		</div>
	)
}
export default SocialShare
