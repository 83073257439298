import React from "react"
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "aos/dist/aos.css";
import get from 'lodash/get'
import StaticIntro from "@components/static-intro/static-intro";
import DetailWave from "@components/detailwave/detailwave";
import GuidesList from "@components/guides-listing/guides-listing";
import FeaturedProperty from "@components/featured-property/featured-property";
import HomeContact from "@components/home-contact/home-contact";
import FormModule from "@components/modules/FormModule/FormModule";
import Valuation from "@components/valuation/valuation";
import AreaFixedBar from "@components/area-fixed-bar/area-fixed-bar";
import SocialShare from "@components/social-share/social-share";
import Officemap from "@components/office-location/office-location";
import FindaProperty from "@components/modal-nav/search-form-link";

import $ from 'jquery';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "gatsby";
import { graphql } from 'gatsby';


class DefaultTemplate extends React.Component {
  componentDidMount() {
    setTimeout(function(){
      $('.header').removeClass('sticky-header');
    }, 3000);
  }
  render() {
    //Detail pages match
    //Default fetch

    const Page = get(this, 'props.data.strapiAreaGuides')
    const GlobalConfig = get(this, 'props.data.strapiGlobalConfig')
    const Guides = get(this, 'props.data.strapiAreaGuides')
    const Modules = get(this, 'props.data.strapiAreaGuides.Modules')
    const TeamMember = get(this, 'props.data.strapiAreaGuides.Team_Members')

    return (
      <>
        <SEO title={Page.Meta_Title} description={Page.Meta_Description} />
        <div className="area-guide-detail">
          <Layout popularSearch="staticdetails" office={Page.Choose_Office} area={Page.URL} footerDetails={GlobalConfig}>
            
            <AreaFixedBar Title={Guides.Title} data={Guides.Guides} office={Guides.Choose_Office} />
            <StaticIntro alias="area-guide" Title={Guides.Title} Intro={Guides.Intro_Content} search="area" />
            {Guides.Video_Background_Image != null &&
              <DetailWave imagetransforms={Guides.imagetransforms} id={Guides.strapiId} Title={Guides.Title} VideoBG={Guides.Video_Background_Image} VideoURL={Guides.Embed_Video_URL} />
            }

            <GuidesList Guides={Guides.Guides} /> 
            <SocialShare />
            
            {Modules.map((Modules, i) => {
              return (
                <>
                  {/* {Modules.Select_Module == "Get_In_Touch" &&
                    <HomeContact data={TeamMember} Content={GlobalConfig.Get_In_Touch_Content} />
                  } */}
                   {Modules.ModuleType == "FormModule" && Modules.FormList =="GetInTouchForm" &&
                    <FormModule ModuleData={Modules} />
                  }
                  {Modules.Select_Module== "Featured_Properties" &&
                  <>

                  <FeaturedProperty officeId='' area={Page.Title} showsale="true" showrent="true" Title={`Featured Properties in ${Page.Title}, London`} />

                  {Modules.Select_Module == "Areaguide_detail_Map" &&
                    <Officemap data={Guides}  Latitude={Guides.Latitude} Longitude={Guides.Longitude} />
                  }
                  </>
                }
                </>
              )
            })}
           </Layout>
      <div className="property-fixed-bar footer-cta d-lg-none">
        <Container>
            <Row className="justify-content-between">
                <Col md="12" lg="auto">
                    <div className="fixed-utils">
                        <FindaProperty label="Find a Property" class="btn dark d-lg-none"/>
                        
                        <Link href="/value-my-property/property-valuation/" className="btn">Valuation</Link>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>

        </div>
      </>
    )
  }
}

export default DefaultTemplate

export const pageQuery = graphql`
query AreaGuidesQuery($slug: String!) {
  strapiAreaGuides(URL: {eq: $slug}) {
    Title
    Meta_Title
    Meta_Description
    strapiId
    Intro_Content
    Embed_Video_URL
    Latitude
    Longitude
    URL
    imagetransforms {
      Video_Background_Image_Transforms
    }
    Video_Background_Image {
      internal {
        description
      }
    }
    Choose_Office {
      Area
      CRM_Office_ID
      Title
      URL
    }
    Guides {
      Description
      Title
      isSpy
      Image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Accordions {
        id
        Content
        Title
      }
    }
    Modules {
      Select_Module
      ModuleType
      FormList
      Section_Title
      ModuleSpacing
      
      Show_Contact
      Show_Reviews
      Section_Intro
      FormContactData {
        Label
        Link
        Type
        Icon_Type
        Show_Icon
      } 
    }
    Team_Members {
          Designation
          Email
          Image {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
          Name
          Phone
    }
  }
  strapiGlobalConfig {
      Get_In_Touch_Content
  }

  allStrapiAreaGuides {
    edges {
      node {
        URL
        Title
      }
    }
  }
  
}
`
